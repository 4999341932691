import React, { use, useMemo } from 'react';
import Image from 'next/image';
import styles from './index.module.less';
import { useTranslation } from 'next-i18next';

const FlowContent = () => {
  const { t } = useTranslation('common');

  const data = [
    {
      title: t('Step 1'),
      desc: t('Sign Up Now'),
    },
    {
      title: t('Step 2'),
      desc: t('Select Your Info'),
    },
    {
      title: t('Step 3'),
      desc: t('Get Your Prep Guide in 1 Minute'),
    },
  ];

  const flowMap = useMemo(() => {
    return data.map((flow) => {
      return (
        <div className={styles.flowItem} key={flow.title}>
          <div className={styles.spaceFlow}>
            <Image
              src={'/images/share/round-icon.png'}
              alt=""
              className={styles.done}
              width={34}
              height={34}
            />
            {/* <div className={styles.line} /> */}
          </div>
          <div className={styles.flowText}>
            <div>{flow.title}:</div>
            <p>{flow.desc}</p>
          </div>
        </div>
      );
    });
  }, []);

  return (
    <div className={styles.flowContent}>
      <h3>{t('Build Custom Emergency Guides in 1 Minute')}</h3>
      <div className={styles.content}>
        <div className={styles.flowList}>{flowMap}</div>
      </div>
    </div>
  );
};

export default FlowContent;
